$(function () {

    (function mobileNavInit() {
        // if department page add flag for toggling between portal and dept menus
        if ($('#seagovMenuMobile').data('departmentname').length > 0) {
            $('#currentMenuContextWrapper').addClass('deptMenu');
            isDept = true;
        }

        // backwards compatiblity for previous version of header2015.cshtml
        if ($('#slideMenu > #currentMenuContextWrapper').length > 0) {
            var $menu = $('#currentMenuContext');
            var navItems = $('#seagovMenuMobile > .navItem');
            $menu.html(navItems.clone(true, true));
            navItems.remove();
        }
    })();

    //default with close class to hide it
    $('#seagovMenuDesktop').addClass('close');
    //add classname to main menu a element
    $('#seagovMenuDesktop .list-group-item a').attr('class', 'list-item');
    var mainMenuToggle = $('#mainMenuToggle')[0];
    if (typeof mainMenuToggle !== "undefined") {
        mainMenuToggle.onclick = function () {

            $(this).addClass('active').attr({'role': 'button', "aria-expanded": 'true'});
            $active = $(".navItem.active").length > 0 ? $(".navItem.active") : $('#currentMenuContextWrapper');

            updateActiveMenuItem($active);
            updateBackButton($active.data('parenttarget'));
            updateHeader($active);

            //for accessibility add focus to this modal
            $('#seagovMenuDesktop').attr('tabindex', '0').removeClass('close').addClass('open').focus();

            showMobileNav();
        };
    }

    $("#seagovMenuMobile li.hasChildren > a").click(function (e) {
       
        var $item = $(this).parent("li");
        if ($item.hasClass("hasChildren")) {
            e.preventDefault();
    
            updateActiveMenuItem($item);
            updateHeader($item);
            updateBackButton($item.data('parenttarget'));
        }
    });
    //initialize the utility dropdowns to be collapsed
    $('#seagovMenuMobile .utility.dropdown .children.collapsed').each(function() {
        $(this).hide();
    });

    $('#seagovMenuMobile .utility.dropdown .title').click(function(e) {
        e.preventDefault();

        //hide/collaps the menu
        if($(this).parent().find('ul.children').hasClass('expanded')) {
            $(this).parent().find('ul.children').hide();
            $(this).parent().find('ul.children').removeClass('expanded').addClass('collapsed');
            $(this).removeClass('expanded').addClass('collapsed');
        }
        else {
            //collapse all dropdowns
            $('#seagovMenuMobile .utility.dropdown .title.expanded').each(function(e) {
                $(this).removeClass('expanded').addClass('collapsed');
                $(this).parent().find('ul.children').removeClass('expanded').addClass('collapsed').hide();
            });

            $(this).parent().find('ul.children').show();
            $(this).parent().find('ul.children').removeClass('collapsed').addClass('expanded');
            $(this).removeClass('collapsed').addClass('expanded');
        }
    });

    $("#backReturn").click(function(e) {
        e.preventDefault();
        var $wrapper = $('#currentMenuContextWrapper');

        // top level of department nav
        if ($wrapper.hasClass('active') && isDept) {
            $wrapper.toggleClass('deptMenu portalMenu');

            // toggle back/return button
            if ($wrapper.hasClass('deptMenu')) {
                updateBackButton('Portal');
                updateHeader($wrapper);
            } else {
                updateBackButton('Home');
                updateHeader($wrapper);
            }
            
            // make sure active menu list is scrolled to top
            $wrapper.scrollTop(0);
        
        // sub level item or top level portal nav
        } else {   
            // set back button to return to parent node
            var $parent = $('.navItem.active').parent().closest('li');
            updateActiveMenuItem($parent);
            updateBackButton($parent.data('parenttarget'));
            updateHeader($parent);
        }
    })
    //Menu Close Button
    $('#mainMenuClose').click(function () {
        closeMainMenu();
    });
    //Menu Close Button when clicking on the enter key (WAI)
    $("#seagovNavbar").on('#mainMenuClose', function(e) { 
		var keyCode = e.keyCode || e.which; 
		//if the enter key was pressed, close the main menu
		if (keyCode == 13) { 
		  e.preventDefault(); 

          closeMainMenu();
		} 
	});
    //pressing the keyboard escape key over the Maim Nenu (right side) closes the modal
    //Menu Close Button when clicking on the enter key (WAI)
    $("#seagovMenuDesktop").on('keydown', function(e) {
        var keyCode = e.keyCode || e.which; 
        //if the enter key was pressed, close the main menu
        if (keyCode == 27) { 
            e.preventDefault();  

            closeMainMenu();
        } 
    });
    //Tabbing out of main menu collapses it
	// if the target of the click isn't the container nor a descendant of the container
    $(document).on('keyup', function(e) {
        if (!$("#seagovMenuDesktop").is(e.target) && $("#seagovMenuDesktop").has(e.target).length === 0) 
        {
            closeMainMenu();
        }
    });

	//arrow keys functions when the main menu is expanded
    if ($('#mainMenuCloseMobile').length > 0) {
        listBoxKeyCommands('.list-item', 'ul');
    }
	
    //Mobile Menu Close Button
    $('#mainMenuCloseMobile').click(function () {
        hideMobileNav();

        //reset menu to orginal state
        var $wrapper = $('#currentMenuContextWrapper');
        $wrapper.find('li').removeClass('active child-active');
        $wrapper.addClass('active');
        if (isDept) {
            $wrapper.removeClass('portalMenu').addClass('deptMenu');
        }
    });
    
    /*search modal */
    $('#searchModal').on("show.bs.modal", function (e) {
        $("#searchIcon i").addClass("fa-times");
        $("#searchIcon i").removeClass("fa-search");
    });
    $('#searchModal').on("hide.bs.modal", function (e) {
        $("#searchIcon i").removeClass("fa-times");
        $("#searchIcon i").addClass("fa-search");
    });
    $(".searchToggles label").click(function (e) {
        $(this).children("input").prop("checked", true);
    });
    /* search cog mobile */
    $("#searchCollectionMobileToggle").click(function () {
        $('#googleSearchToggle_M').toggle("slide", { direction: "up" }); 
    });

    /*Mac Fix Detection*/
    if(navigator.platform == 'MacIntel'){
      $('body').addClass('mac');
      if(navigator.userAgent.indexOf('Firefox') < 0){
        $('body').addClass('notFF');
      }
    }

    $('.padTop.MainColumnInsertComponent .colorBlock').removeClass('colorBlock');

    //singlerow tilesets not be in colorblocks so we need to adjust the layout
    if ($('#rightCol').length || $('#leftCol').length) {

        if ($('.padTop.SingleRowTilesetComponent').length) {
            $('.padTop.SingleRowTilesetComponent')
                .each(function () {
                    jQuery(this).addClass('withNav');
                });
        }

        //remove taxonomyTileWider if theres a left/right sidebar
        if ($('.taxonomyTile.taxonomyTileWider').length) {
            $('.taxonomyTile.taxonomyTileWider')
                .each(function () {
                    jQuery(this).removeClass('taxonomyTileWider');
                });
            $('.textDescriptionTile.textDescriptionTileWider')
                .each(function () {
                    jQuery(this).removeClass('textDescriptionTileWider');
                });
        }
    }

    //tab components supporting anchors/hash
    if ($('#tabs.tabs').length > 0) {
        setLocationAnchorsForTabs();
    }

    //accordion components supporting anchors/hash
    if ($('.accords').length > 0) {
        //add foreign language attribute to the button
        setAccessibilityLanguageCode();
        //accordion related functions
        setLocationAnchorsForAccordion();
        reloadPageForAnchorTag();
    }

    //2021 global header
    //pay or apply click 
    $('#supplementalLink').click(function() {
        window.location = '//' + window.location.hostname + '/' + $('#seagovNavbar #supplementalLink').attr('data-url');
    })

    //new 2020 tabs design
    if ($('.tab.tabsWrapper').length > 0) {
        var target = '';

        //loop through each tab
        $('.tab.tabsWrapper').each(function() {
            var tab = $(this).closest('.viewTabs');
            //get the parent wrapper
            target = '#' + $(this).closest('.viewTabs').attr('id') + ' ';

            //TEMP - LEGACY
            $(target + '.navBarWrap').find('a.tab:first').addClass('active').attr('aria-selected', 'true');
            //default to first content visible
            $(target + '.content .tabContent').not(':first').hide();
            $(target + '.content .tabContent:first').show();
            //TEMP

            //if there are more than 2 tabs, disable the scrollSpy 'sticky' feature
            //if only 1 component that has scrollSpy, continue
            //do not include the 2021 home page
            if ($('.tab.tabsWrapper').length == 1 && $('#home.home').length == 0) {
                if ($('#scrollNav, .scrollNav').length == 1) {
                    attachTabsAnchor();
                    $(window).scroll(attachTabsAnchor);   
                } 
            } else {
                positionGradients(target);
            }

            setUrlHashForTabs();
            tabClick(tab);   
        });

        $('.tab.tabsWrapper .tab').on('keydown', function(e) {
            var keyCode = e.keyCode || e.which; 
            //if the space key was pressed
            if (keyCode == 32) { 
                e.preventDefault(); 
                $(this).click();
            }
        });
    }

    //by default, don't display the SEO Publish Date globally. It will be enabled by department-related JS
    $('#seoGroupGlobal').hide();

    //don't show the photo credit in the featureTileWrapper B tiles
    $('#featuredTileContainer .taxonomyTile .photoCredit').hide(); 

    // notificaiton banner is collapsed if content is overflowing the container
    $('.seagovBanner').each(function() {
        var $element = this;
        if ($element.scrollHeight > $element.clientHeight) {
            $(this).addClass('isOverflown');
        }
    }) 

    // notification banner can be expanded and collapsed on mobile
    $('.seagovBanner button').click(function() {

        var $banner = $(this).parent('.seagovBanner');
        
        if ($banner.hasClass('isExpanded')) {
            $banner.animate({height: '64px'});
            $(this).attr('aria-label', 'Expand');
        } else {
            // hacky way to animate expansion to 100% height
            $banner.css('height', '100%');
            var fullHeight = $banner.outerHeight();
            $banner.css('height', '64px');
            $banner.animate({height: fullHeight});
            $(this).attr('aria-label', 'Collapse');
        }

        $banner.toggleClass('isExpanded isOverflown');
        $(this).children('svg').toggle();
    })

    //add a fontawesome external link icon to external and download links
    $('.colorBlock div:not(".tilesWrapper, .card"):not(".ColorBlockTilesetComponent .fullWidth") > a:not(":has(img)"):not(".taxonomyTileLinkWrapper"), .MainColumnTwoColumnComponent.colorBlock .container a, #mainColContent a:not(":has(img)"):not(".taxonomyTileLinkWrapper"):not(".ui-link"):not(".CardContainerComponent .card a"), .mainColWrapper .ScrollSpyContainerComponent a, .servicesSection .rightWrapper a, .servicesSection .leftWrapper .excerpt a, .TwoColumnImageandTextGridComponent .nameOfTitleWithLink, .TwoColumnImageandTextGridComponent.colorBlock p a, .boardCommissionHomePage .container a, .departmentContacts .departmentAgency h3 a, .topNavigation.button a, .topNavigation.dropDown a, .notificationContainer a, .MainColumnInsertComponent.colorBlock a, [id^=request-lists] ol>li a, [id^=popular-lists] ol>li a, #quickInfoApplyButton a').each(function() {

        if (!$(this).hasClass('externalLink')) {
            var hrefAttribute = $(this).attr('href'); 

            //if the element isn't a link, find the closest parent that is
            if (undefined == hrefAttribute) {
                hrefAttribute = $(this).closest('a').attr('href'); 
            }

            if (undefined != hrefAttribute) {
                var isDoc = isDocument(hrefAttribute);

                //add a class for external links
                if ((hrefAttribute.indexOf('http') >=0 && !isInternalDomain(hrefAttribute) || isSocialLink(hrefAttribute)) || isDoc) {
                    $(this).addClass('externalLink')
                } 
                
                if ($(this).hasClass('externalLink')) {
                    var hrefTitle = isDoc? 'download' : 'external link';
                    var fontAwesomeClass = isDoc? 'far fa-file-download' : 'far fa-external-link';
                    $(this).addClass('externalLink').append('<i class="' + fontAwesomeClass + '" title="' + hrefTitle + '" />');
                }
            }
        }
    });

	//about nav footer, keep the first, 'about' link floated to the left if the other links wrap
    $('#deptFootLinksWrapperShort').addClass('aboutNav');
    $(window).resize(function () {
        //resizeDepartmentFooter(); 
    });

    // Transition for dropdown in global footer
    $( "#seaFooter20-reportProblem .dropdown-toogle" ).on('click', function() {
        $( ".dropdown-menu" ).fadeToggle( 400, "linear" ).focus();
    });
    $( "#seaFooter20-reportProblem .dropdown-toogle, #seaFooter20-reportProblem a" ).on('blur', function() {
        setTimeout(function() {
            var focused = $(document.activeElement)
            if (!focused.hasClass('problem') && focused.parents('#seaFooter20-reportProblem').length === 0) {
                $( ".dropdown-menu" ).fadeOut( 400, "linear" );
                $("#seaFooter20-reportProblem").removeClass("open");
            }
        }, 1)
    });

    //wire up the static menu to open the accordions
    if ($('#navBarWrap_static').length > 0) {
		$('.navbar-nav_static li a').click(function() {
			var hrefHash = $(this).attr('href');
			if (undefined != hrefHash) {
				var splitHash = hrefHash.split('#');
			}

			$('.accords').find($('h2[name=' + splitHash[1] + ']')).click();
            //scroll to the header with the anchor
            if ($('h2[name=' + splitHash[1] + ']').length) {
                $("html, body").animate({ scrollTop: $('h2[name=' + splitHash[1] + ']').offset().top - 200 }, 1000);
            }
        });
    }

    //TEMP until mvc is deployed
    if ($('.topicSidebar').length > 0) { 
        if ($('.topicSidebar .topicSubhead.keyword').text() == '') {
            $('.topicSidebar .topicSubhead.keyword').text('Filter by keyword');
        }
    }
    
    //campaign page
    $('.department.Campaign #btn_campaignFormClear').on('click', function (event) {
        $("#saveMoney").toggle();
        $("#campaignInputForm").toggle();
        $("#hideshow").toggle();
    });

    $(".department.Campaign #btn_campaignFormShow").click(function () {
        $("#hideshow").toggle();
        $("#saveMoney").toggle();
        $("#campaignInputForm").toggle();
    });

    $(".department.Campaign .mainColumnTwoColumn img").parent().css({
        "margin-bottom": "0px"
    }); 

    // Title/Topnav/Breadcrumb Border Temporary Hotfix
    hotfix__topBorders();

    //department & services filter
    $('#servicesFilter #filter_button').on('keydown', function(e) {
        var keyCode = e.keyCode || e.which; 
        //if the enter key was pressed, open the drop down
        if (keyCode == 13) { 
            e.preventDefault(); 

            filterServices();
        }
    });

    //department & services cancel/reset filter
    $('#servicesFilter #clear_button').attr('tabindex', '0').on('keydown', function(e) {
        var keyCode = e.keyCode || e.which; 
        //if the enter key was pressed, open the drop down
        if (keyCode == 13) { 

            e.preventDefault(); 

            clearKeywordFilter(true);
        }
    });

    //remove breadcrumbs when there is no parent page
    // on majorevent page, topic (portal services), or event (portal main)
    if ($('.MajorEvent #breadCrumbWrapper a').length === 1) {
        $("#breadCrumbWrapper .container").hide();
        $("#breadCrumbWrapper").css("padding", "0px 0px");
        $(".container.banner").css("margin-top", "0px");
    }
    if ($('.PortalServices #breadCrumbWrapper a').length === 1
        || $('.PortalMain #breadCrumbWrapper a').length === 1) {
        $("#breadCrumbWrapper .container").hide();
        $("#breadCrumbWrapper").css("padding", "0px 0px");
        $("#breadCrumbWrapper").css("border-top", "none");
    }

    if (window.location.href.indexOf("wwwqa.seattle") > -1) {
        //change the header background color to dark red if its wwwqa
        $('.navbar-default, .navbar-default .navbar-collapse, #seagovNavbar, .navbar-default .navbar-header #supplementalLink').css("background-color", "#8b0000");
        $('.seattleMayor').addClass('qaOnly');
    }

    //videos should have an aspect ratio of 16:9
    if ($('.scrollSpyContainer .video-responsive .embedVideo').length > 0) {
        var videoWidth = $('.scrollSpyContainer .video-responsive .embedVideo').width();
        $('.scrollSpyContainer .video-responsive .embedVideo').css({
            "width": videoWidth,
            "height": videoWidth*(9/16)
        });
    }   

    //wrap scroll spy container buttons to a div 
    $(".colorBlock .bottomDocumentLinks").wrap("<div class='container'></div>");

    //camel case for the new home page title
    $(".home2021 .tileSetHeader").html("City of Seattle Official Website");

    //change link from x40.xml 
    $("#seaFooter20-contactSubHead a").attr("href", "customer-service-bureau");

    //new quote component in main col with sidebar
    if ($('.quote22.mainColumn_Quote').length > 0) {
        //if the sidebar exists
        if($('#rightCol').length > 0) {
            $('.quote22.mainColumn_Quote').addClass('rightColAdjust');
        }
        if($('#leftCol').length > 0) {
            $('.quote22.mainColumn_Quote').addClass('leftColAdjust');
        }
    }

    //department banner title adjustment
    if ($('.departmentBanner').length > 0) {
        if ($('.departmentBanner.image').length > 0) {
            $('.departmentBanner .bannerTitle').removeClass('col-lg-12 col-md-12 col-sm-12').addClass('col-lg-9 col-md-9 col-sm-8');

            //if there's an unusually long page title that will overlap the 120px background banner, add padding to the banner
            if ($('.departmentBanner .bannerTitle').height() > $('.departmentBanner .wrap').height()) {
                $('.departmentBanner .wrap').addClass('flex').css('height', $('.departmentBanner .bannerTitle').height() + 40 + 'px');
                $('.departmentBanner .bannerImage').css('margin-top', -(65 - ($('.departmentBanner .bannerTitle').height() + 40 - 120) / 2)  + 'px');
            }

            var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
            var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

            //bug in iOS ipad safari to dectect offset.left. Extend the gray banner full width
            if (isSafari && iOS) {
                $('.departmentBanner .rightOverLay').addClass('ipad');
            } else {
                //position the white background tile to extend from the banner image to the right viewport
                $('.rightOverLay').attr('style', 'left: ' + ($('.departmentBanner .bannerImage img').offset().left + $('.departmentBanner .bannerImage').width()/2) + 'px');
            }
        }
    }

    // Hotfix: Footer Social Media Icons
    // Add an empty span for white fill
    $('footer .contactTileSocialMediaWrapper a').each(function(i,link) {
        $(link).append('<span class="hoverFill"/>')
    })

    //add class to tables
    ////$("table").addClass("table table-responsive table-striped table-hover");
    $('table:not(.table)').addClass("table");
    $('table').wrap('<div class="table-responsive"></div>');

    //truncate rss cards title and descriptions
    updateRssCardCharLength();
    //switch to mobile view for the photogallery on tablet and mobile
    updatePhotoGalleryView();

    //resize the title and desc for the landscape cards
    $(window).resize(function () {
        updateCardCharLength();
        updateRssCardCharLength();
        updatePhotoGalleryView();
        closeOpenPhotoGalleryModal();
    });

    // If both sidebars are present, setting the columns to full width for report highlight items in MainColTwoCol.
    if ($('#leftMainRight.threeColRow div#mainColMain.col-md-8').length > 0) {
        $('div#mainColMain.col-md-8 .mainColumnTwoColumn .twoColLeft .reportHighlightIconDiv').closest('.twoColLeft').css('width', '100%');
        $('div#mainColMain.col-md-8 .mainColumnTwoColumn .twoColRight .reportHighlightIconDiv').closest('.twoColRight').css('width', '100%');
    }

    // giving equal height to report highlight items in MainColTwoCol, so that the items are aligned column wise. 
    $(window).resize(function () {
        if ($(window).width() > 991) {
            $('#leftMainRight.noLeftBorder div#mainColMain.col-md-9 .mainColumnTwoColumn,#leftMainRight.noRightBorder div#mainColMain.col-xs-12 .mainColumnTwoColumn, #leftMainRight.row div#mainColMain.col-xs-12 .mainColumnTwoColumn, .MainColumnTwoColumnComponent.colorBlock, .ScrollSpyContainerComponent.colorBlock').each(function () {
                var iconHighlightOnLeftColumn = $('.twoColLeft .reportHighlightIconDiv', this);
                var iconHighlightOnRightColumn = $('.twoColRight  .reportHighlightIconDiv', this);
                for (var index = 0; index < Math.min(iconHighlightOnLeftColumn.length, iconHighlightOnRightColumn.length); index++) {
                    var iconHighlightHeight = Math.max($(iconHighlightOnLeftColumn[index]).outerHeight(), $(iconHighlightOnRightColumn[index]).outerHeight());
                    $(iconHighlightOnLeftColumn[index]).css({ 'height': iconHighlightHeight + "px" });
                    $(iconHighlightOnRightColumn[index]).css({ 'height': iconHighlightHeight + "px" });
                }
            });
        }
        else {
            $('.reportHighlightIconDiv').css('height', '');
        }
    });


    // limiting number highlight's numberTitle and Subtitle to certain amount of characters. 
    $('.numberHighlightComponent .shape h3.number span.numberTitle').each(function (f) {

        var numbertitletext = $(this).text().substring(0, 8);
        $(this).text(numbertitletext);

    });
    $('.numberHighlightComponent .shape h3.number span.subtitle').each(function (f) {

        var numbersubtitletext = $(this).text().substring(0, 48);
        $(this).text(numbersubtitletext);

    });

    //media, departments, boards and commissions & elected officials dropdown
    if ($('#topicSidebar #leftNavMobileSelect').length > 0) {
        $('#topicSidebar #leftNavMobileSelect .dropdown-toggle').text($('h1.pageTitle').text());
    }

    //skip to main link
    $('#header #skipToMain').attr('href', location.protocol + '//' + location.host + location.pathname + '#content');

    // Declare iterators.
    var ctr1, ctr2;

    //search for photogallery component with hero-carousel option
    // Gather all modal boxes and assign events to the buttons inside
    var modalHeroCarousels = document.querySelectorAll(
        ".modalPhotoGallery-HeroCarousel"
    );
    var modalHeroCarousel, prevHeroCarousel, nextHeroCarousel;
    for (ctr1 = 0; ctr1 < modalHeroCarousels.length; ctr1++) {
        modalHeroCarousel = modalHeroCarousels[ctr1];

        // add listener when user press left or right arrow key
        modalHeroCarousel.addEventListener(
            "keydown",
            leftRightArrowModalKeydownHeroCarousel
        );

        // Next button
        nextHeroCarousel = modalHeroCarousel.querySelector(".next");
        nextHeroCarousel.addEventListener("click", nextHeroCarouselSlide2);
        nextHeroCarousel.addEventListener("keypress", nextModalKeypress);

        // Prev button
        prevHeroCarousel = modalHeroCarousel.querySelector(".prev");
        prevHeroCarousel.addEventListener("click", prevHeroCarouselSlide2);
        prevHeroCarousel.addEventListener("keypress", prevModalKeypress);

        // loop through the images and display default
        // add events to the footer thumbnail inside the modal
        var heroCarouselFooterThumbnails =
            modalHeroCarousel.getElementsByClassName("dots");
        for (ctr2 = 0; ctr2 < heroCarouselFooterThumbnails.length; ctr2++) {
            // Assign an event listener to the image
            heroCarouselFooterThumbnails[ctr2].addEventListener(
                "click",
                displayClickedFooterImageInTheCarousel2
            );
            heroCarouselFooterThumbnails[ctr2].addEventListener(
                "keypress",
                footerImageKeypress
            );
            heroCarouselFooterThumbnails[ctr2].setAttribute("data-number", ctr2);
        }

        showSlide2(modalHeroCarousel, 0);
    }

    //start of masonry option
    //search for photogallery component with masonry option
    var photoGalleryControlComponentMasonry = document.querySelectorAll(
        ".photoGallery_FF.photoGalleryDisplay-Masonry.masonContainer"
    );

    photoGalleryControlComponentMasonry.forEach(function (element, index) {
        // Prepare each image in the row
        var alinks;
        alinks = element.getElementsByTagName("a");
        for (ctr2 = 0; ctr2 < alinks.length; ctr2++) {
            // Use the image's position as its slide number and save it in an attribute
            alinks[ctr2].setAttribute("data-number", ctr2);

            // Assign an event listener to the image
            alinks[ctr2].addEventListener("click", openModalMasonry2);
            alinks[ctr2].addEventListener("keypress", openModalKeypressMasonry2);
        }
    });
    //end of masonry option

    //start of thumbnail option
    //search for photogallery component with thumbnail option
    var photoGalleryControlComponentThumbnail = document.querySelectorAll(
        ".photoGallery_FF.photoGalleryDisplay-Thumbnail.row"
    );

    photoGalleryControlComponentThumbnail.forEach(function (element, index) {
        // initialize photogalleries - nflores

        var controlId = element.classList[2];
        //var className = ".photoGalleryDisplay-Thumbnail" + "." + controlId + ".row";

        // Prepare each image in the row
        var alinks;
        alinks = element.getElementsByTagName("a");
        for (ctr2 = 0; ctr2 < alinks.length; ctr2++) {
            // Use the image's position as its slide number and save it in an attribute
            alinks[ctr2].setAttribute("data-number", ctr2);

            // Assign an event listener to the image
            alinks[ctr2].addEventListener("click", openModal2);
            alinks[ctr2].addEventListener("keypress", openModalKeypress2);
        }

        //build pagination
        var numberPerPage = 12;
        var currentPage = 1;

        // Number of pages
        var numberOfPages = Math.ceil(alinks.length / numberPerPage);

        //display the 140x140 images set of 12 per page
        buildPage2(alinks, 1, numberPerPage);

        // get div.photoGallery_FF.pageNumbers
        var pageNumbersDiv = element.nextElementSibling;
        //add event to next and prev pagination button
        // Prev button
        var prev = pageNumbersDiv.querySelector(".prevItem");
        prev.addEventListener("click", prevSetOfPages2);

        // Next button
        var next = pageNumbersDiv.querySelector(".nextItem");
        next.addEventListener("click", nextSetOfPages2);

        //get <span class="photoGalleryPaginator"></span>
        var paginationDiv = element.nextElementSibling.children[1];

        buildPagination2(paginationDiv, currentPage, numberOfPages, controlId);

        //add events to page numbers when clicked
        $(paginationDiv).on("click", "button", function () {
            var clickedPage = parseInt($(this).val());
            PageClick2(paginationDiv, alinks, clickedPage);
        });
    }); //end of photoGalleryControlComponentThumbnail

    // Gather all modal boxes and assign events to the buttons inside (Thumbnail and Masonry option)
    var modals = document.querySelectorAll(".modalPhotoGallery");
    var modal, close, prev, next;
   
    for (modalCtr = 0; modalCtr < modals.length; modalCtr++) {
        
        modal = modals[modalCtr];
        //listen for left right arrow on modal
        modal.addEventListener("keydown", leftRightArrowModalKeydown);
        
        // Close button modal
        close = modal.querySelector(".closeModal");
        close.addEventListener("click", closeModal2);
        close.addEventListener("keypress", closeModalKeypress);

        // Next button modal
        next = modal.querySelector(".next");
        next.addEventListener("click", nextSlide2);
        next.addEventListener("keypress", nextModalKeypress);

        // Prev button modal
        prev = modal.querySelector(".prev");
        prev.addEventListener("click", prevSlide2);
        prev.addEventListener("keypress", prevModalKeypress);

        // add events to the footer thumbnail inside the modal
        var modalFooterThumbnails = modal.getElementsByClassName("dots");
        for (ctr2 = 0; ctr2 < modalFooterThumbnails.length; ctr2++) {
            // Assign an event listener to the image
            modalFooterThumbnails[ctr2].addEventListener(
                "click",
                displayClickedFooterImageInTheCarousel2
            );
            modalFooterThumbnails[ctr2].addEventListener(
                "keypress",
                footerImageKeypress
            );
            modalFooterThumbnails[ctr2].setAttribute("data-number", ctr2);
        }
    }

    //search for all the mobile version of the carousel applies to all options
    var photoCarouselMobile = document.querySelectorAll(
        ".photoGallery_FF.photoGalleryDisplay-Thumbnail.mobileVersion, .photoGallery_FF.photoGalleryDisplay-Masonry.mobileVersion, .photoGallery_FF.photoGalleryDisplay-HeroCarousel.mobileVersion"
    );

    photoCarouselMobile.forEach(function (element, index) {
        ////add events to every minicarousel for left right arrow accessibility
        //var miniCarousel = element.getElementsByClassName("miniCarousel");

        //for (ctrA = 0; ctrA < miniCarousel.length; ctrA++) {
        //    // Assign an event listener to the image
        //    miniCarousel[ctrA].addEventListener(
        //        "keydown",
        //        leftRightArrowModalKeydownMobileVersion
        //    );
        //    miniCarousel[ctrA].addEventListener("click", setMiniCarouselFocus);
        //}

        //loop through every a tags w img inside the carousel and set the data-number
        var carouselImg = element.getElementsByClassName(
            "PhotoGalleryItem__CoverLink"
        );

        for (ctr2 = 0; ctr2 < carouselImg.length; ctr2++) {
            // Assign an event listener to the image
            carouselImg[ctr2].addEventListener("click", openCarouselModal2);
            carouselImg[ctr2].addEventListener(
                "keypress",
                openCarouselModal2Keypress
            );
            carouselImg[ctr2].setAttribute("data-number", ctr2);
        }

        // Prepare each image in the row
        var prevCarousel = element.getElementsByClassName("prevRightCol");

        for (ctr2 = 0; ctr2 < prevCarousel.length; ctr2++) {
            // Assign an event listener to the image
            prevCarousel[ctr2].addEventListener("click", plusSlidesRightColPrev2);
            prevCarousel[ctr2].addEventListener("keypress", prevMiniCarouselKeypress);
        }

        var nextCarousel = element.getElementsByClassName("nextRightCol");

        for (ctr2 = 0; ctr2 < nextCarousel.length; ctr2++) {
            // Assign an event listener to the image
            nextCarousel[ctr2].addEventListener("click", plusSlidesRightColNext2);
            nextCarousel[ctr2].addEventListener("keypress", nextMiniCarouselKeypress);
        }
    }); //end of ".photoGallery_FF.photoGalleryDisplay-Thumbnail.mobileVersion"

    var photoGalleryControlComponent = document.querySelectorAll(
        ".photoGallery_FF.photoGalleryDisplay-Thumbnail"
    );

    if ($("#rightCol").length > 0) {
        if ($(".photoGallery_FF.mobileVersion").length > 0) {
            $(".prevRightCol").css("display", "inline-block");
            $(".nextRightCol").css("display", "inline-block");
        }
    }

    //toggle which translation content to show on desktop
    $(".translationTabComponent_ff .btnTranslation").click(function () {
        var getbtnid = $(this).data("id");
        var xid = getbtnid.substr(getbtnid.indexOf('_') + 1, getbtnid.length - getbtnid.indexOf('_'));
        $("#" + xid + " .commonClass").hide();
        $("." + getbtnid).show();
        $("#" + xid + " .btnTranslation").removeClass("myactivebutton");
        $(this).addClass("myactivebutton");

        //append the selected translation language to the url
        var url = $(location).attr('href');

        var stripHash = url.substr(0, url.indexOf('#'));
        var pathname = window.location.pathname;
        if (stripHash == "") {
            history.pushState({}, null, pathname + '#' + $(this).attr('data-id'));
        }
        else {
            history.pushState({}, null, stripHash + '#' + $(this).attr('data-id'));
        }
        

    });

    //toggle which translation content to show on mobile
    $(".translationTabComponent_ff .mobileDDLTranslate").change(function () {

        var getbtnid = $(this).val();
        var xid = getbtnid.substr(getbtnid.indexOf('_') + 1, getbtnid.length - getbtnid.indexOf('_'));
        $("#" + xid + " .commonClass").hide();
        $("." + getbtnid).show();

        //append the selected translation language to the url
        var optionSelected = $("option:selected", this);
        var valueSelected = this.value;
        var url = $(location).attr('href');
        var stripHash = url.substr(0, url.indexOf('#'));
        history.pushState({}, null, stripHash + '#' + valueSelected);

    });


    //add language to the select dropdown list if there is a content
    if ($('.TranslationTabComponent .btnTranslation').length) {
        $('.translationTabComponent_ff').each(function () {
            var compId = $(this).prop('id');

            var mobileDDLTranslateId = "#mobileDDLTranslate_" + compId;

            if ($('.translationTabComponent_ff .Es_' + compId).html()) {
                $('<option value="Es_' + compId + '">Español</option>').appendTo(mobileDDLTranslateId);
            }
            if ($('.translationTabComponent_ff .ZhTw_' + compId).html()) {
                $('<option value="ZhTw_' + compId + '">中國語文</option>').appendTo(mobileDDLTranslateId);
            }
            if ($('.translationTabComponent_ff .ZhCn_' + compId).html()) {
                $('<option value="ZhCn_' + compId + '">简体中文</option>').appendTo(mobileDDLTranslateId);
            }
            if ($('.translationTabComponent_ff .Vi_' + compId).html()) {
                $('<option value="Vi_' + compId + '">Tiếng Việt</option>').appendTo(mobileDDLTranslateId);
            }
            if ($('.translationTabComponent_ff .So_' + compId).html()) {
                $('<option value="So_' + compId + '">Af-Soomaali</option>').appendTo(mobileDDLTranslateId);
            }
            if ($('.translationTabComponent_ff .Tl_' + compId).html()) {
                $('<option value="Tl_' + compId + '">Tagalog</option>').appendTo(mobileDDLTranslateId);
            }
            if ($('.translationTabComponent_ff .Ko_' + compId).html()) {
                $('<option value="Ko_' + compId + '">한국어</option>').appendTo(mobileDDLTranslateId);
            }
            if ($('.translationTabComponent_ff .Am_' + compId).html()) {
                $('<option value="Am_' + compId + '">አማርኛ</option>').appendTo(mobileDDLTranslateId);
            }
            if ($('.translationTabComponent_ff .Ru_' + compId).html()) {
                $('<option value="Ru_' + compId + '">русский язык</option>').appendTo(mobileDDLTranslateId);
            }
            if ($('.translationTabComponent_ff .Ja_' + compId).html()) {
                $('<option value="Ja_' + compId + '">日本語</option>').appendTo(mobileDDLTranslateId);
            }
            if ($('.translationTabComponent_ff .Ti_' + compId).html()) {
                $('<option value="Ti_' + compId + '">ትግርኛ</option>').appendTo(mobileDDLTranslateId);
            }
            if ($('.translationTabComponent_ff .Om_' + compId).html()) {
                $('<option value="Om_' + compId + '">Oromiffa</option>').appendTo(mobileDDLTranslateId);
            }
            if ($('.translationTabComponent_ff .Hi_' + compId).html()) {
                $('<option value="Hi_' + compId + '">हिन्दी</option>').appendTo(mobileDDLTranslateId);
            }
            if ($('.translationTabComponent_ff .Fr_' + compId).html()) {
                $('<option value="Fr_' + compId + '">Français</option>').appendTo(mobileDDLTranslateId);
            }
            if ($('.translationTabComponent_ff .Uk_' + compId).html()) {
                $('<option value="Uk_' + compId + '">Українська</option>').appendTo(mobileDDLTranslateId);
            }
            if ($('.translationTabComponent_ff .Th_' + compId).html()) {
                $('<option value="Th_' + compId + '">ภาษาไทย</option>').appendTo(mobileDDLTranslateId);
            }
            if ($('.translationTabComponent_ff .Km_' + compId).html()) {
                $('<option value="Km_' + compId + '">ភាសាខ្មែរ</option>').appendTo(mobileDDLTranslateId);
            }
            if ($('.translationTabComponent_ff .Lo_' + compId).html()) {
                $('<option value="Lo_' + compId + '">ພາສາລາວ</option>').appendTo(mobileDDLTranslateId);
            }
            if ($('.translationTabComponent_ff .Pa_' + compId).html()) {
                $('<option value="Pa_' + compId + '">ਪੰਜਾਬੀ</option>').appendTo(mobileDDLTranslateId);
            }
            if ($('.translationTabComponent_ff .Ar_' + compId).html()) {
                $('<option value="Ar_' + compId + '">العربية</option>').appendTo(mobileDDLTranslateId);
            }

            setLocationAnchorsForTranslation(compId);
        });

    } //end of if ($('.TranslationTabComponent .btnTranslation').length)


    if ($('.translationComponentFullPage_ff').length) {
        $('.translationComponentFullPage_ff').each(function () {
            //var compId = $(this).prop('id');

            //setLocationAnchorsForFullPageTranslation(compId);
            setLocationAnchorsForFullPageTranslation();
        });

        //add notranslate class
        $('#leftMainRight').addClass("notranslate");
    }  


});

/***********************************************************************************
 * 
 * Mobile nav helper functions
 */
var isDept = false;

//update the title and desc length for landscape cards on tablet and mobile view
function updateCardCharLength() {
    var $containerWidth = $(window).width();

    if ($containerWidth <= 991) {
        $('.card.landscapewiththumbnail .cardTitle').each(function (index, value) {
            var charLength = $(this).text().length;
            if (charLength > 60) {
                $(this).html($(this).html().substring(0, 60) + ' ...');
            }
        });
        $('.card.landscapewiththumbnail .cardExcerpt').each(function (index, value) {
            var charLength = $(this).text().length;
            if (charLength > 60) {
                $(this).html($(this).html().substring(0, 60) + ' ...');
            }
        });
        $('.card.landscapewithicon .cardTitle').each(function (index, value) {
            var charLength = $(this).text().length;
            if (charLength > 60) {
                $(this).html($(this).html().substring(0, 60) + ' ...');
            }
        });
        $('.card.landscapewithicon .cardExcerpt').each(function (index, value) {
            var charLength = $(this).text().length;
            if (charLength > 60) {
                $(this).html($(this).html().substring(0, 60) + ' ...');
            }
        });
    }
}


//update the title and desc length for landscape cards on tablet and mobile view
function updateRssCardCharLength() {
    var $containerWidth = $(window).width();

    $('.feedcard.rsscard_ff .noThumbnail .card-text').each(function (index, value) {
        var charLength = $(this).text().length;
        if (charLength > 115) {
            $(this).html($(this).html().substring(0, 115) + ' ...');
        }
    });

    $('.feedcard.rsscard_ff .withThumbnail .card-text').each(function (index, value) {
        var charLength = $(this).text().length;
        if (charLength > 57) {
            $(this).html($(this).html().substring(0, 57) + ' ...');
        }
    });

    $('.home2021 .rsscard_ff .card-title').each(function (index, value) {
        var charLength = $(this).text().length;
        var dispExcerpt = false;
        if (charLength > 93) {
            $(this).html($(this).html().substring(0, 93) + ' ...');
            //hide excerpt
            $(this).next(".card-text").css({ "display": "none" });
        }
        else if (charLength > 57) {
            //hide excerpt
            $(this).next(".card-text").css({ "display": "none" });
        }
        else if (charLength < 57) {
            //display excerpt
            $(this).next(".card-text").css({ "display": "block" });
        }
    });

    $('.home2021 .rsscard_ff .card-text').each(function (index, value) {
        var charLength = $(this).text().length;
        if (charLength > 57) {
            $(this).html($(this).html().substring(0, 57) + ' ...');
        }
    });

    $('#rightCol .rsscard_ff .card-text').each(function (index, value) {
        var charLength = $(this).text().length;
        if (charLength > 57) {
            $(this).html($(this).html().substring(0, 57) + ' ...');
        }
    });

    $('#rightCol .rsscard_ff .card-title').each(function (index, value) {
        var charLength = $(this).text().length;
        var dispExcerpt = false;
        if (charLength > 93) {
            $(this).html($(this).html().substring(0, 93) + ' ...');
            //hide excerpt
            $(this).next(".card-text").css({ "display": "none" });
        }
        else if (charLength > 57) {
            //hide excerpt
            $(this).next(".card-text").css({ "display": "none" });
        }
        else if (charLength < 57) {
            //display excerpt
            $(this).next(".card-text").css({ "display": "block" });
        }
    });


    if ($containerWidth >= 1292) {
        //large desktop title excerpt thumbnail but post does not have an image
        $('#mainColMain .rsscard_ff .noThumbnail .card-title, .colorBlock:not(.MainColumnTabListComponent) .rsscard_ff .noThumbnail .card-title').each(function (index, value) {
            //alert("resize rss card");
            var charLength = $(this).text().length;
            var dispExcerpt = false;
            if (charLength > 225) {
                $(this).html($(this).html().substring(0, 225) + ' ...');
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength > 115) {
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength < 115) {
                //display excerpt
                $(this).next(".card-text").css({ "display": "block" });
            }
        });


        //large desktop title excerpt thumbnail but post has an image
        $('#mainColMain .rsscard_ff .withThumbnail .card-title, .colorBlock:not(.MainColumnTabListComponent) .rsscard_ff .withThumbnail .card-title').each(function (index, value) {
            //alert("resize rss card");
            var charLength = $(this).text().length;
            var dispExcerpt = false;
            if (charLength > 185) {
                $(this).html($(this).html().substring(0, 185) + ' ...');
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength > 110) {
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength < 110) {
                //display excerpt
                $(this).next(".card-text").css({ "display": "block" });
            }
        });

        //maincolumntwocolumn in a colorblock outside of maincolmain
        $('.MainColumnTwoColumnComponent.colorBlock .rsscard_ff .card-title').each(function (index, value) {
            //alert("resize rss card");
            var charLength = $(this).text().length;
            var dispExcerpt = false;
            if (charLength > 185) {
                $(this).html($(this).html().substring(0, 185) + ' ...');
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength > 110) {
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength < 110) {
                //display excerpt
                $(this).next(".card-text").css({ "display": "block" });
            }
        });

        $('.noLeftBorder .mainColumnTwoColumn .rsscard_ff .card-title').each(function (index, value) {
            var charLength = $(this).text().length;
            if (charLength > 110) {
                $(this).html($(this).html().substring(0, 110) + ' ...');
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength > 65) {
                //do not display excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength < 65) {
                //display excerpt
                $(this).next(".card-text").css({ "display": "block" });
            }
        });

        $('.threeColRow .mainColumnTwoColumn .rsscard_ff .card-title').each(function (index, value) {
            var charLength = $(this).text().length;
            if (charLength > 110) {
                $(this).html($(this).html().substring(0, 110) + ' ...');
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength > 65) {
                //do not display excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength < 65) {
                //display excerpt
                $(this).next(".card-text").css({ "display": "block" });
            }
        });
    }
    else if ($containerWidth >= 992 && $containerWidth <= 1291) {

        //small desktop
        $('#mainColMain .rsscard_ff .withThumbnail .card-title').each(function (index, value) {
            //alert("resize rss card");
            var charLength = $(this).text().length;
            var dispExcerpt = false;
            if (charLength > 185) {
                $(this).html($(this).html().substring(0, 185) + ' ...');
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength > 110) {
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength < 110) {
                //display excerpt
                $(this).next(".card-text").css({ "display": "block" });
            }
        });

        $('#mainColMain .rsscard_ff .noThumbnail .card-title').each(function (index, value) {
            //alert("resize rss card");
            var charLength = $(this).text().length;
            var dispExcerpt = false;
            if (charLength > 225) {
                $(this).html($(this).html().substring(0, 225) + ' ...');
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength > 115) {
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength < 115) {
                //display excerpt
                $(this).next(".card-text").css({ "display": "block" });
            }
        });


        //small desktop with two sidebars
        $('.threeColRow #mainColMain .rsscard_ff .card-title').each(function (index, value) {
            var charLength = $(this).text().length;
            if (charLength > 110) {
                $(this).html($(this).html().substring(0, 110) + ' ...');
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength > 65) {
                //do not display excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength < 65) {
                //display excerpt
                $(this).next(".card-text").css({ "display": "block" });
            }
        });

        $('.mainColumnTwoColumn .rsscard_ff .card-title').each(function (index, value) {
            var charLength = $(this).text().length;
            if (charLength > 110) {
                $(this).html($(this).html().substring(0, 110) + ' ...');
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength > 65) {
                //do not display excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength < 65) {
                //display excerpt
                $(this).next(".card-text").css({ "display": "block" });
            }
        });

    }


    else if ($containerWidth <= 991 && $containerWidth > 767) {
        //tablet
        $('#mainColMain .rsscard_ff .withThumbnail .card-title').each(function (index, value) {
            //alert("resize rss card");
            var charLength = $(this).text().length;
            var dispExcerpt = false;
            if (charLength > 185) {
                $(this).html($(this).html().substring(0, 185) + ' ...');
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength > 110) {
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength < 110) {
                //display excerpt
                $(this).next(".card-text").css({ "display": "block" });
            }
        });

        $('#mainColMain .rsscard_ff .noThumbnail .card-title').each(function (index, value) {
            //alert("resize rss card");
            var charLength = $(this).text().length;
            var dispExcerpt = false;
            if (charLength > 225) {
                $(this).html($(this).html().substring(0, 225) + ' ...');
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength > 115) {
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength < 115) {
                //display excerpt
                $(this).next(".card-text").css({ "display": "block" });
            }
        });

        $('#mainColumnTwoColumn .rsscard_ff .withThumbnail .card-title').each(function (index, value) {
            //alert("resize rss card");
            var charLength = $(this).text().length;
            var dispExcerpt = false;
            if (charLength > 185) {
                $(this).html($(this).html().substring(0, 185) + ' ...');
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength > 110) {
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength < 110) {
                //display excerpt
                $(this).next(".card-text").css({ "display": "block" });
            }
        });

        $('#mainColumnTwoColumn .rsscard_ff .noThumbnail .card-title').each(function (index, value) {
            //alert("resize rss card");
            var charLength = $(this).text().length;
            var dispExcerpt = false;
            if (charLength > 225) {
                $(this).html($(this).html().substring(0, 225) + ' ...');
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength > 115) {
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength < 115) {
                //display excerpt
                $(this).next(".card-text").css({ "display": "block" });
            }
        });
    }
    else if ($containerWidth <= 767) {

        //mobile
        $('#mainColMain .rsscard_ff .card-title').each(function (index, value) {
            var charLength = $(this).text().length;
            if (charLength > 110) {
                $(this).html($(this).html().substring(0, 110) + ' ...');
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength > 65) {
                //do not display excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength < 65) {
                //display excerpt
                $(this).next(".card-text").css({ "display": "block" });
            }
        });

        $('.mainColumnTwoColumn .rsscard_ff .card-title').each(function (index, value) {
            var charLength = $(this).text().length;
            if (charLength > 110) {
                $(this).html($(this).html().substring(0, 110) + ' ...');
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength > 65) {
                //do not display excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength < 65) {
                //display excerpt
                $(this).next(".card-text").css({ "display": "block" });
            }
        });

        $('.colorBlock .rsscard_ff .card-title').each(function (index, value) {
            var charLength = $(this).text().length;
            if (charLength > 110) {
                $(this).html($(this).html().substring(0, 110) + ' ...');
                //hide excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength > 65) {
                //do not display excerpt
                $(this).next(".card-text").css({ "display": "none" });
            }
            else if (charLength < 65) {
                //display excerpt
                $(this).next(".card-text").css({ "display": "block" });
            }
        });

        $('#mainColMain .feedcard.rsscard_ff .noThumbnail .card-text').each(function (index, value) {
            var charLength = $(this).text().length;
            if (charLength > 57) {
                $(this).html($(this).html().substring(0, 57) + ' ...');
            }
        });

        $('.colorBlock .feedcard.rsscard_ff .noThumbnail .card-text').each(function (index, value) {
            var charLength = $(this).text().length;
            if (charLength > 57) {
                $(this).html($(this).html().substring(0, 57) + ' ...');
            }
        });

    }
}

//card containers in tabs needs to have their isotope and slick re-attached
function scrollSpyTabIsotope(element) {
    //card containers istotope needs to be re-attached when clicking on individual tabs
    var elementDataId = element.attr('data-id');

    if ($('#home .viewTabs #' + elementDataId + ' .serviceList, .department .ScrollSpyContainerComponent.colorBlock .viewTabs #' + elementDataId + ' .serviceList').length) {

        //mobile target includes '.slick-track'
        if ($(window).width() < 575) {

            //unslick the cards
            $('#home .viewTabs #' + elementDataId + ' .serviceList .row, .department .ScrollSpyContainerComponent.colorBlock .viewTabs #' + elementDataId + ' .servicesSection .serviceList .row, .department .ScrollSpyContainerComponent.colorBlock .viewTabs #' + elementDataId + ' .reportsSection .serviceList .row').slick('unslick');

            //target the elements for isotope
            $services = $('#home .viewTabs #' + elementDataId + ' .serviceList .slick-track, .department .ScrollSpyContainerComponent.colorBlock .viewTabs #' + elementDataId + ' .servicesSection .serviceList .slick-track');
 
            var tallestTileHeight = 0;
            var cardContainerWidth = 0;
            //level the cards to the maximum height
            $('#' + elementDataId).find('.card').each(function() {
                tallestTileHeight = tallestTileHeight > $(this).height() ? tallestTileHeight : $(this).outerHeight();
                cardContainerWidth += $(this).width() + 400;
            });  

            //position the card wrappers
            $('.viewTabs #' + elementDataId + ' .servicesSection .serviceList').css({'width': cardContainerWidth + 'px', 'height': tallestTileHeight + 'px'});
            $('.viewTabs #' + elementDataId + ' .servicesSection .servicesWrapper, .viewTabs #' + elementDataId + ' .servicesSection .servicesWrapper .slick-track').css({'width': cardContainerWidth + 'px', 'height': tallestTileHeight + 40 + 'px'});

            //re-attach Slick 
            attachSlick('#home .viewTabs #' + elementDataId + ' .serviceList .row, .department .ScrollSpyContainerComponent.colorBlock .viewTabs #' + elementDataId + ' .serviceList .row');

            //related/last services row tile count
            positionTileCounter(element);
        } else {
            $services = $('#home .viewTabs #' + elementDataId + '  .serviceList, .department .ScrollSpyContainerComponent.colorBlock .viewTabs #' + elementDataId + '  .serviceList');

            //set height to the home page card container
            $('#home .viewTabs .tab.active .servicesWrapper').height($('#home .viewTabs .serviceList').height());
        }

        initIsotope($services);
    }
}

function initIsotope($services) {
    // init Isotope
    if ($services.length) {
        $services.isotope({
            // options
            itemSelector: '.taxonomyTile, .card',
            layoutMode: 'fitRows',
            transitionDuration: '0.0s'
        });
        $services.isotope('on','layoutComplete', srTileFilter); 
    }
}

function attachSlick(element) {
    $('' + element + '').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        centerMode: false,
        variableWidth: true,
        arrows: false,
        rows: 1,
        touchThreshold: 5000
    });
}

// clear and set the active menu item 
// item and children made visible with css
function updateActiveMenuItem($item) {
    $('#currentMenuContextWrapper, .navItem').removeClass('active');
    $item.addClass('active').removeClass('child-active');
    $item.parents('li.hasChildren').addClass('child-active');

    // make sure active menu list is scrolled to top
    $('#currentMenuContextWrapper').scrollTop(0);
}

// update labels and destination on back button depending on current state
function updateBackButton(parent) {
    var isDeptMenu = $('#currentMenuContextWrapper').hasClass('deptMenu');
    var isPortalMenu = $('#currentMenuContextWrapper').hasClass('portalMenu');
    
    $('#backReturn, #backReturn svg').hide();

    // Portal nav
    if (parent == "Portal") {
        $('#backReturnText').text('Main Menu');
        $('#backReturn, #backReturn svg.fa-chevron-left').show();

    // Department nav -- toggle back or return button
    } else if (parent == "Home") {
        if (isPortalMenu) {
            $('#backReturn, #backReturn svg.fa-chevron-right').show();
        } else {
            $('#backReturn, #backReturn svg.fa-chevron-left').show();   
        }
        $('#backReturnText').text($('#seagovMenuMobile').data('departmentname'));

    // Deaprtment nav
    } else if (parent != undefined) {
        $('#backReturnText').text(parent);
        $('#backReturn, #backReturn svg.fa-chevron-left').show();
    
    // Department or portal homepage
    } else {
        if (isDeptMenu) {
            $('#backReturnText').text('Main Menu');
            $('#backReturn, #backReturn svg.fa-chevron-left').show();
        } else {
            $('#backReturn, #backReturn svg').hide();
        }
    }
}

function updateHeader($item) {
    var $active = $('#seagovMenuMobile .active:not(#activeMobileHeading)');

    // top level item is active
    // header will be seattle.gov in portal menu ot department name
    if ($item.attr('id') == "currentMenuContextWrapper") {
        if (isDept && $item.hasClass('deptMenu')) {
            var href = $('#seagovMenuMobile').data('departmenturl');
            var text = $('#seagovMenuMobile').data('departmentname');
            $('#activeMobileHeading a').attr('href', href).text(text);
        } else {
            $('#activeMobileHeading a').attr('href', '//www.seattle.gov').text('Home');
        }

    // menu sub item is active    
    } else {
        var href = $('.navItem.active > a').attr('href');
        var text = $active.hasClass('hasChildren') ? $item.find('ul')[0].dataset.childrentarget : $('.navItem.active > a').text();

        $('#activeMobileHeading a').attr('href', href).text(text);
        $('#activeMobileHeading').addClass('active');
    }
}

function showMobileNav() {
    $('html, body').addClass('no-scroll');
    $('.slidemenu-right').addClass('slidemenu-open');
            
    if (!$('#searchCollectionMobileToggle').length) { $('#searchButton_M').css('right', '10px'); }
    if ($(".slidemenu-right:visible")[0].id == "seagovMenuMobile") {
        $("#content").addClass("slidemenu-open", 100, "easeInOutQuad");
    }

    var closeButtonHeight = $("#closeMobileContainer").outerHeight();
    //$("#seagovMenuMobile #backReturn").css("min-height", newHeight);
    $("li#currentMenuContextWrapper").css("max-height", $(window).height() - closeButtonHeight);
    $('<div class="contentOverlay"></div>').appendTo('header').hide().fadeIn(200);
}

function hideMobileNav() {
    $('html, body').removeClass('no-scroll');
    $('.slidemenu-right').removeClass('slidemenu-open');
    $("#content").removeClass("slidemenu-open", 100, "easeInOutQuad");
    $('.contentOverlay').fadeOut(500, function () { $(this).remove(); });
}

function setLocationAnchorsForTabs() {
    //get the hashtag in the url  
    var urlHash = location.hash;
    //check if url has hashtag
    if (urlHash !== "") {
        //url has hashtag, remove non alphanumeric characters
        var hashValue = urlHash.replace(/%20/g, '').replace(/[\W_]+/g, '').toLowerCase();
        $('li.ui-state-default.ui-corner-top').each(function () {
            //remove styles to all the tabs  
            $(this).removeClass('ui-tabs-active ui-state-active').attr({ 'aria-selected': 'false' });
        });

        //add style to make the tab look active
        $('li[name=' + hashValue + ']').addClass('ui-tabs-active ui-state-active').attr({ 'aria-selected': 'true' });
        //call the click event to display the content
        $('li[name=' + hashValue + ']' + ' a.ui-tabs-anchor').click();
    }
    preventPageJump('li.ui-state-default.ui-corner-top', 'name');
}

//set the url hash tag with the name attribute
function setUrlHashForTabs() {
    //get the hashtag in the url  
    var urlHash = location.hash;
    //check if url has hashtag
    if (urlHash !== "") {
        //url has hashtag, remove non alphanumeric characters
        var hashValue = urlHash.replace(/%20/g, '').replace(/[\W_]+/g, '').toLowerCase();

        //click on the matching tab from the url hash
        $('a[name=' + hashValue + '] p').click();
    }

    preventPageJump('.tab.tabsWrapper .tab', 'name', true);
}
/**
 * END Mobile nav helper functions
 ***********************************************************************************/

 function srTileFilter(filteredItems) {
    // Reset --- Hide all tile wrappers from ADA tools
    $('.taxonomyTileLinkWrapper, .card').attr('tabindex', '-1');
    // Allow filtered tiles to be visible to ADA tools
    $.each(filteredItems, function(index, item) {
        $(item.element).parent('.taxonomyTileLinkWrapper, .card').removeAttr('tabindex');
    }); 
}

function setAccessibilityLanguageCode() {
    //add foreign language attribute to the button
    $('.accords.accordsFa h2').each(function() {
        var languageCode = $(this).attr('name');

        if (isGoogleTranslateCountryCode(languageCode)) {
            switch (languageCode) {
                case "zhtw":
                    languageCode = 'zh-TW';
                    break;
                case "zhcn":
                    languageCode = 'zh-CN';
                    break;
                default:
                    languageCode = languageCode;
                    break;
            }
    
            $(this).attr('name', languageCode);
            $(this).find('.toggleBtn').attr('lang', languageCode);
            $('.accordionWrapper [data-name=' + languageCode + ']').attr('lang', languageCode);
        }
    });
}

function setLocationAnchorsForAccordion() {
    //get the hashtag in the url  
    var urlHash = location.hash;
    //check if url has hashtag
    if (urlHash !== "") {
        //url has hashtag, remove non alphanumeric characters
        var hashValue = urlHash.replace(/%20/g, '').replace(/[\W_]+/g, '').toLowerCase();
        //add style to make the tab look active
        $('h2[name=' + hashValue + ']').addClass('ui-accordion-header-active ui-state-active ui-corner-top').attr({ 'tabindex': '0', 'aria-selected': 'true' });
        //call the click event to display the panel for the accordion
        $('h2[name=' + hashValue + ']').click();
        //scroll to the header with the anchor
        if ($('h2[name=' + hashValue + ']').length) {
            $("html, body").animate({ scrollTop: $('h2[name=' + hashValue + ']').offset().top }, 500);
        }
    }
    preventPageJump('h2.ui-accordion-header', 'name')
}

function reloadPageForAnchorTag() {
    //yes, page have accordions
    $('a[href*="#"]').each(function () {
        //yes, href has anchor tag, get the hash name
        var hashname = this.hash;
        var linkUrl = $(this).attr("href");

        //check if its a seattle.gov page
        if (linkUrl.indexOf("seattle.gov") >= 0 || linkUrl.indexOf("ditwinw520") >= 0 || linkUrl.indexOf("dsspreview") >= 0) {
            //seattle.gov page, then do a reload if there's a hashtag
            if (hashname.length && (hashname.indexOf('tabs-') < 0)) {
                $(this).click(function () {
                    location.hash = hashname;
                    location.reload();
                });
            }
        }
    });
}

//need to do this whether there is a hash or not.
//just in case they started with a page with no hash
//if you don't do this the url wont change
function preventPageJump(parentWrapper, targetElement, isNewDesign) {

    var pathName = $(location).attr('pathname');
    pathName = pathName + '#';
    pathName = pathName.slice(1, pathName.length);
    pathName = isNewDesign ? window.location.href + '#' : pathName;

    $(parentWrapper).click(function () {

        if (history.pushState) {
            //if production
            if (location.toString().indexOf("seattle.gov") > 0 || isNewDesign) {
                history.pushState(null, null, pathName + $(this).attr(targetElement) + '');
            } else {
                history.pushState(null, null, '#' + $(this).attr(targetElement));
            }
        }
        else {
            location.hash = '#' + $(this).attr(targetElement) + '';
        }

        //check if there are no active accordions
        if (parentWrapper == "h2.ui-accordion-header") {
            removeHashtag('.ui-accordion-header-active');
        }	

    });
}

//remove hashtag if there's no active accordion'
function removeHashtag(cssClass) {
    if ($(cssClass).length == 0) {
        history.pushState('', document.title, window.location.pathname)
    }
}

//scroll to the component otherwise, it'll jump down too far
function scrollToElement(targetElement, offsetTopValue) {
    $('html, body').animate({
        scrollTop: $(targetElement).offset().top - offsetTopValue
    }, 300);
}

//determines if link is to a document by evaluating the extension
function isDocument(href) {
    var extension = href.substr((href.lastIndexOf('.') + 1));
    var isDoc = false;

    switch (extension.toLowerCase()) {
        case 'pdf':
        case 'doc':
        case 'docx':
        case 'ppt':
        case 'pptx':
    	case 'xls':
    	case 'xlsm':
        case 'xlsx':
            isDoc = true;
            break;
        default:
            isDoc = false
    }

    return isDoc;
}

//show/hide the chart loading graphic
function loadChartMessage(chart, duration) {
    chart.showLoading('<i title="Please wait spinner" class="fas fa-spin fa-spinner" class="webTraffic_load"></i>');
    //hide the loading graphic
    setTimeout(function () {
        chart.hideLoading();
    }, duration)
}

//resizes the department footer bar
function resizeDepartmentFooter() {
    if ($('#deptFootLinksWrapperShort').height() > 69 && $(window).width() > 991) {
        $('#deptFootLinksWrapperShort .footerLinkList li:first-child').css('height', $('#deptFootLinksWrapperShort').height());
    }
} 

//determines if links are internal 
function isInternalDomain(href) {
	var isInternal = false;

	if(href.indexOf('seattle.gov') >= 0 || href.indexOf('ditwinw146') >= 0 || href.indexOf('ditwinw319') >= 0 || href.indexOf('ditwinw520') >= 0 || href.indexOf('ditwinw518') >= 0 || href.indexOf('powerappsportals') >= 0) {
		isInternal = true;
	}

	return isInternal;
}

//determines if social media links
function isSocialLink(href) {
	var isSocial = false;

	if(href.indexOf('digg') >= 0 || href.indexOf('instagram') >= 0 || href.indexOf('facebook') >= 0 || href.indexOf('flickr') >= 0 || href.indexOf('google') >= 0 || href.indexOf('pinterest') >= 0 || href.indexOf('reddit') >= 0 || href.indexOf('tumblr') >= 0 || href.indexOf('twitter') >= 0 || href.indexOf('youtube') >= 0) {
		isSocial = true;
    }

	return isSocial;
}

//scroll spy for tabs
function attachTabsAnchor() { 
    var window_top = $(window).scrollTop();
    var top = $('.stick-top').offset().top;
    bottom = $('.tabEnd').offset().top - 300;

    $('.scrollNav').show();

    if (window_top > top) {
        //account for the city authenticity banner's height
        var topPosition = isAuthenticityBannerPresent() ? $('#seagovNavbar').outerHeight() + $('.officialBanner').outerHeight() : $('#seagovNavbar').outerHeight();
        $('.scrollNav').addClass('stick').css('top', topPosition + 'px');

        if ($(window).width() > 577) {
            $('.tabContent').css({'padding-top': '192px'})
        } else {
            $('.tabContent').css({'padding-top': '264px'})
        }
    } else {
        $('.scrollNav').removeClass('stick').css('top', '');
        $('.stick-top').height(0);
        $('.tabContent').css({'padding-top': '30px'})
    }

    if (window_top > bottom) {
        $('.scrollNav').hide();
    }

    //make tabs the same width as parent
    $('.tab .navbar.stick').width(parseInt($('.tabsContainer .tab').width() + 2));

    positionGradients(); 
}

function scrollArrow(element) {
    //handle the right and left arrow click
    var target = '#' + $(element).closest('.viewTabs').attr('id') + ' ';

    $(target + '.navBarWrap').find('active');
    //TEMP
    
    //get the total width of tabs
    var tabsWidth = calculateElementsWidth(target, '.navBarWrap .tab');  
    //get the average tab width
    var averageTabWidth = parseInt(tabsWidth / $('.tab').length);
    //calculate the left and right offset values
    var offset = $(element).attr('data-icon').indexOf('right') > 0 ? averageTabWidth + $(target + '.scrollNav').scrollLeft() : $(target + '.scrollNav').scrollLeft() - averageTabWidth;

    //scroll either right or left
    $('.scrollNav').animate({scrollLeft: offset}, 500);
}

//scrolls the anchor into center view
function displayTab(element, onDemand) { 
    var target = '#' + $(element).closest('.viewTabs').attr('id') + ' ';
    //update tab label attributes
    $(target + '.navBarWrap .tab').each(function() {
        //initialize attributes
        $(this).removeClass('active').attr('aria-selected', false).attr('tabindex', -1);
    });

    $(element).addClass('active').attr('aria-selected', true);
    var activeTab = $(element).attr('data-id');
    //update tab content attributes
    $(target + '.content .tabContent').attr({ 'aria-hidden': true});
    $(target + '.content .tabContent').hide();

    $(target + '.content').find('#' + activeTab).attr('aria-hidden', false);
    $(target + '.content').find('#' + activeTab).show();

    var elOffset = onDemand ? $(element).offsetLeft : $(element)[0].offsetLeft;
    var elWidth = $(element).width();
    var containerWidth = $(target + '.scrollNav').width();
    var offset = elOffset - ((containerWidth - elWidth) / 2);

    $(target + '.scrollNav').animate({scrollLeft: offset}, 1);

    if ($(target + '.scrollNav.stick').length > 0) {
        //scroll the tab content to the top
        scrollToElement('.stick-top', $('#seagovNavbar').outerHeight(true) + $('.officialBanner').outerHeight());
    }

    positionGradients(element);  
}

//displays the right and left gradients w/ arrows.
function positionGradients(element) {

    var container = '';
    var inner = '';
    var target = ''; 

    if (undefined != element) {
        target = '#' + $(element).closest('.viewTabs').attr('id') + ' ';
    }

    //start from the first tab
    $(target + '.navBarWrap a:first-child p').click();
    $(target + '.navBarWrap .gradient-right').css('left', $(target + '.tabsContainer .scrollNav').outerWidth() - 100 + 'px'); 
    $(target + '.navBarWrap .gradient-left').css('left', 0 + 'px').hide(); 

    container = $(target + '.scrollNav');
    inner = $(target + '.navBarWrap');

    realignGradients(container, inner, target); 

    $(window).scroll(function() {
        //right gradient position and visibility
        realignGradients(container, inner, target);
    });

    container.scroll(function() {
        //right gradient position and visibility
        realignGradients(container, inner, target);
    });
}

//right gradient position and visibility
function realignGradients(container, inner, target) {

    var wrapper = $('.tabsContainer').length > 0 ? '.tabsContainer' : '#skipToDiv';
    var element = $('.tabsContainer').length > 0 ? '.tabsContainer .scrollNav' : '#scrollNav';
    var navBarWrap = $('.tabsContainer').length > 0 ? '.navBarWrap' : '#navBarWrap';
    var elementsWidth = $('.tabsContainer').length > 0 ? '.navBarWrap .tab' : '#navBarWrap li';
    var gradientWidth = 100;

    $(target + navBarWrap + ' .gradient-right').hide();
    $(target + navBarWrap + ' .gradient-left').hide();

    //left gradient position and visibility 
    var mobilePadding = $(target + wrapper).width() >= 626 ? 1 : 2;

    //get the sum of the individual tabs
    var tabsWidth = calculateElementsWidth(target, elementsWidth);

    if (parseInt(container.scrollLeft() + container.outerWidth()) == parseInt(inner.outerWidth() + mobilePadding)) {
        $(target + navBarWrap + ' .gradient-right').hide();
        $(target + navBarWrap + ' .gradient-left').show();
    }
    //if the .navbarWrap element isn't equal to the sum of the .tab width
    else if (parseInt(inner.outerWidth() - mobilePadding) == tabsWidth || 
        parseInt(container.scrollLeft() + container.outerWidth()) == parseInt(inner.outerWidth() + 2)) {
        $(target + navBarWrap + ' gradient-right').hide();
    }
    else {
        $(target + navBarWrap + ' .gradient-right').css('left', (parseInt($(target + element).outerWidth() + container.scrollLeft()) - gradientWidth) + 'px'); 
        $(target + '.gradient-right').show();
    }

    //if the active element is the last to the right, remove the right gradient just in case
    if ($(target + navBarWrap + ' li.item').last().hasClass('active') ||
        $(target + navBarWrap + ' a.tab').last().hasClass('active'))  {
        $(target + navBarWrap + ' .gradient-right').hide();
    }

    //left gradient position and visibility
    if (container.scrollLeft() == 0) {
        $(target + '.gradient-left').hide();
    }
    else {
        $(target + '.gradient-left').css('left', container.scrollLeft() + 'px'); 
        $(target + '.gradient-left').show();
    }
}

//wires up to the tab click to ensure only one hash is applied.
function tabClick(tab) {
    $('.navBarWrap .tab').click(function() {
        //this prevents multiple url hashes
        var url = window.location.href;
        var stripHash = url.substr(0,url.indexOf('#'));
        history.pushState({}, null, stripHash + '#' + $(this).attr('name'));
    })

    positionGradients(tab);
}

//gets the total width of tabs
function calculateElementsWidth(target, elements) {
    var elementWidth = 0;
    $(target + elements).each(function() {
        elementWidth += $(this).outerWidth();
    }); 

    return elementWidth;
}

//closes the Main Menu on the right side
function closeMainMenu() {
    $('#seagovMenuDesktop').removeClass('open').addClass('close');
    $('.slidemenu-right').removeClass('slidemenu-open');
    $('html, body').removeClass('no-scroll');

    //for accessibility, togle the visibilty of the modal to false
    $('#mainMenuToggle').attr({"aria-expanded": 'false'});    
}

/* 
    Page Title / TopNav / Breadcrumb
    Border & Spacing Control - Bruteforce Hotfix
    User Story #1705
    * 
    Delete after refactoring the
    nesting of all these sections 
    and writing proper css
    *
*/
var hotfix__topBorders = function(){
    var wrapper = $('.titleTopNavBreadcrumbWrapper'),
        breadcrumbs = $('#breadCrumbWrapper'),
        featuredTiles = $('#featuredTileContainer'),
        extendedCarousel = $('#extendedCarousel'),
        fullImageBanner = $('.bannerHomeFullImage'),
        portalBanner = $('.portalBanner'),
        megaMenu = $('#vue-component-mega-menu, #mega-menu');

    var has = function(array) { return array.length > 0 }

    if (has(wrapper)) {
        // Border Fixes
        if (has(breadcrumbs) || has(featuredTiles) && !has(extendedCarousel)) {
            // Remove bottom border
            wrapper.css('border-bottom', '0');
        }
        if (has(megaMenu) && has(portalBanner) || has(megaMenu) && has(fullImageBanner)) {
            // Remove top 
            breadcrumbs.css('border-top', '0');
        }
        
        // Spacing Fixes
        if (has(extendedCarousel) && has(featuredTiles)) {
            featuredTiles.css('margin-top', '0');
        }
        if (has(fullImageBanner)) {
            fullImageBanner.closest('.banner').css('margin-top', '0');
        }
    }
}

// keyboard arrow keys interacting with listbox, dropdowns
function listBoxKeyCommands(element, parentElement) {
	$(document).on('keydown', element, function(e)
	{    
		// Down key
		if (e.keyCode == 40) {     
            if (parentElement == 'ul') {
                $(element + ':focus').closest('li').next().find(element).focus();   
            } else if (parentElement == 'div') {
                $(element + ':focus').next().focus(); 
            }
			
			e.preventDefault(); 
		}
	
		// Up key
		if (e.keyCode == 38) {    
            if (parentElement == 'ul') {
			    $(element + ':focus').closest('li').prev().find(element).focus();   
            } else if (parentElement == 'div') {
                $(element + ':focus').prev().focus();   
            }

			e.preventDefault(); 
		}

		// home key
		if (e.keyCode == 36) {     
			$(element + ':first()').focus();    

			e.preventDefault(); 
		}

		// end key
		if (e.keyCode == 35) {     
			$(element + ':last-child').focus();  

			e.preventDefault(); 
		}
	});    
} 

//google translate country codes
function isGoogleTranslateCountryCode(language) {
    var i;
    var list = ['am','ar','cs','es','fr','hi','ja','km','ko','ms','my','ne','or','pa','ps','pt','ru','so','th','ti','tl','uk','vi','zh-CN','zh-TW','zhtw','zhcn'];
    for (i = 0; i < list.length; i++) {
        if (list[i] === language) {
            return true;
        }
    }

    return false;
}

//open the modal for thumbnail
function openModal2(e) {
    var alink = e.currentTarget;

    // This assumes the HTML structure is exactly as you posted
    var modal =
        alink.parentNode.nextElementSibling.nextElementSibling.nextElementSibling;

    // Set the display to block
    modal.style.display = "block";
    $(modal).find(".prev").focus();

    // Choose a slide
    if (alink.hasAttribute("data-number")) {
        var slideNum = alink.getAttribute("data-number");
        showSlide2(modal, slideNum);
    }
}

//open the modal for masonry
function openModalMasonry2(e) {
    var alink = e.currentTarget;

    // This assumes the HTML structure is exactly as you posted
    var modal = alink.parentNode.nextElementSibling.nextElementSibling;

    // Set the display to block
    modal.style.display = "block";

    // Choose a slide
    if (alink.hasAttribute("data-number")) {
        var slideNum = alink.getAttribute("data-number");
        showSlide2(modal, slideNum);
    }
}

//open the modal for mini carousel (rightcol, small container)
//no need to open the modal on mobile
function openCarouselModal2(e) {
    var alink = e.currentTarget;
    var carousel = e.currentTarget.parentNode.parentNode;
    var modal = e.currentTarget.parentNode.parentNode.nextElementSibling;

    // Set the display to block
    modal.style.display = "block";
    $(modal).find(".prev").focus();

    // Choose a slide
    if (alink.hasAttribute("data-number")) {
        var slideNum = alink.getAttribute("data-number");
        //display the modal
        showSlide2(modal, slideNum);
    }
}

// open the modal when user press enter or space in the mini carousel
function openCarouselModal2Keypress(e) {
    if (e.key == "Enter" || e.key == " ") {
        openCarouselModal2(e);
    }
    e.preventDefault();
}

function closeModal2(e) {
    var modal = e.currentTarget.parentNode.parentNode;
    modal.style.display = "none";
}

function closeModalKeypress(e) {
    if (e.key == "Enter" || e.key == " ") {
        closeModal2(e);
    }
    e.preventDefault();
}

//when user click enter or space
function nextModalKeypress(e) {
    if (e.key == "Enter" || e.key == " ") {
        // This assumes the HTML structure is exactly as you posted
        var modal = e.currentTarget.parentNode.parentNode;
        var num = modal.getAttribute("data-slide");
        if (!num) num = 0;
        num++;
        showSlide2(modal, num);
    }
    e.preventDefault();
}

//when user click enter or space
function prevModalKeypress(e) {
    if (e.key == "Enter" || e.key == " ") {
        // This assumes the HTML structure is exactly as you posted
        var modal = e.currentTarget.parentNode.parentNode;
        var num = modal.getAttribute("data-slide");
        if (!num) num = 0;
        num--;
        showSlide2(modal, num);
    }
    e.preventDefault();
}

//when user click left or right arrow inside the modal
function leftRightArrowModalKeydown(e) {
    //left 37, right 39
    if (e.key == "ArrowLeft" || e.key == "ArrowRight") {
        var modal = e.currentTarget;
        var num = modal.getAttribute("data-slide");
        if (!num) num = 0;
        if (e.key == "ArrowLeft") {
            num--;
        } else if (e.key == "ArrowRight") {
            num++;
        }
        showSlide2(modal, num);
    }
    //   e.preventDefault();
}

function setMiniCarouselFocus(e) {
    var carousel = e.currentTarget.parentNode;
    //get the current carousel slide
    var num = carousel.getAttribute("data-slide");
    if (!num) num = 0;
    showCarouselSlide2(carousel, num);
}

//when user click left or right arrow in the mini carousel
function leftRightArrowModalKeydownMobileVersion(e) {
    //left 37, right 39
    if (e.key == "ArrowLeft" || e.key == "ArrowRight") {
        // miniCarousel >  mobileversion
        var carousel = e.currentTarget.parentNode;
        //get the current carousel slide
        var num = carousel.getAttribute("data-slide");
        if (!num) num = 0;
        if (e.key == "ArrowLeft") {
            num--;
        } else if (e.key == "ArrowRight") {
            num++;
        }
        showCarouselSlide2(carousel, num);
    }
    //e.preventDefault();
}

//when user click left or right arrow in the hero carousel
function leftRightArrowModalKeydownHeroCarousel(e) {
    //left 37, right 39
    if (e.key == "ArrowLeft" || e.key == "ArrowRight") {
        var modal = e.currentTarget;
        var num = modal.getAttribute("data-slide");
        if (!num) num = 0;
        if (e.key == "ArrowLeft") {
            num--;
        } else if (e.key == "ArrowRight") {
            num++;
        }
        showSlide2(modal, num);
    }
    //   e.preventDefault();
}

//next button in the modal
function nextSlide2(e) {
    // .next > .modalPhotoGallery-content > .modalPhotoGallery
    var modal = e.currentTarget.parentNode.parentNode;
    var num = modal.getAttribute("data-slide");
    if (!num) num = 0;
    num++;
    showSlide2(modal, num);
}

//previous button in the modal
function prevSlide2(e) {
    // .prev > .modalPhotoGallery-content > .modalPhotoGallery
    var modal = e.currentTarget.parentNode.parentNode;
    var num = modal.getAttribute("data-slide");
    if (!num) num = 0;
    num--;
    showSlide2(modal, num);
}

//search for the next slide in the hero carousel
function nextHeroCarouselSlide2(e) {
    // .next > .modalPhotoGallery-content > .modalPhotoGallery
    var modal = e.currentTarget.parentNode.parentNode;
    var num = modal.getAttribute("data-slide");
    if (!num) num = 0;
    num++;
    showSlide2(modal, num);
}

//search for the prev slide in the hero carousel
function prevHeroCarouselSlide2(e) {
    // .prev > .modalPhotoGallery-content > .modalPhotoGallery
    var modal = e.currentTarget.parentNode.parentNode;
    var num = modal.getAttribute("data-slide");
    if (!num) num = 0;
    num--;
    showSlide2(modal, num);
}

//display slides and dots in the modal
function showSlide2(modal, num) {
    var slides = modal.getElementsByClassName("mySlides");
    var dots = modal.getElementsByClassName("dots");
    // var captionText = modal.querySelector(".caption p");
    if (num >= slides.length) {
        num = slides.length - 1;
    }
    if (num < 0) {
        num = 0;
    }
    //display the slides in the modal
    for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
    }
    // display the dots in the modal
    displayModalFooterThumbnails2(dots, num);
    // for (i = 0; i < dots.length; i++) {
    //   dots[i].className = dots[i].className.replace(" active", "");
    // }
    slides[num].style.display = "block";
    // dots[num].className += " active";
    // captionText.innerHTML = dots[num].alt;

    // Store the current slide in an attribute
    modal.setAttribute("data-slide", num);
}

// display the slide in the carousel (mobile version)
function showCarouselSlide2(carousel, num) {
    var slides = carousel.getElementsByClassName("miniCarousel");
    //var slides = carousel.getElementsByClassName("mySlidesMini-Thumbnail");
    //var dots = carousel.getElementsByClassName("dotsMini");

    if (num >= slides.length) {
        num = slides.length - 1;
    }
    if (num < 0) {
        num = 0;
    }
    for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
    }
    //for (i = 0; i < dots.length; i++) {
    //    dots[i].className = dots[i].className.replace(" active", "");
    //}
    slides[num].style.display = "block";
    //dots[num].className += " active";
    $(slides[num]).children(":first").focus();
    //carousel = PhotoGalleryItem__CoverLink
    carousel.setAttribute("data-slide", num);
}

// display the slide in the carousel (mobile version)
function showSelectedImageInModal2(carousel, num) {
    var slides = carousel.getElementsByClassName("mySlides");
    //var slides = carousel.getElementsByClassName("mySlidesMini-Thumbnail");
    var dots = carousel.getElementsByClassName("dots");

    if (num >= slides.length) {
        num = 0;
    }
    if (num < 0) {
        num = slides.length - 1;
    }
    for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
    }
    slides[num].style.display = "block";
    dots[num].className += " active";

    //carousel = PhotoGalleryItem__CoverLink
    carousel.setAttribute("data-slide", num);
}

// prev button in the mobile version carousel
function plusSlidesRightColPrev2(e) {
    // prevrightcol > span > pagecounter > minicarousel > mobileVersion
    var carousel = e.currentTarget.parentNode.parentNode.parentNode.parentNode;

    //get the current carousel slide
    var num = carousel.getAttribute("data-slide");
    if (!num) num = 0;
    num--;

    //if num is the first disable TODO 1026
    showCarouselSlide2(carousel, num);

    ////display set of footer thumbnails
    //displaySetOfFooterThumbnails2(carousel, num);
}

// prev button in the mobile version carousel when enter or space
function prevMiniCarouselKeypress(e) {
    if (e.key == "Enter" || e.key == " ") {
        var carousel = e.currentTarget.parentNode.parentNode.parentNode.parentNode;

        //get the current carousel slide
        var num = carousel.getAttribute("data-slide");
        if (!num) num = 0;
        num--;

        //if num is the first disable TODO 1026
        showCarouselSlide2(carousel, num);
    }
    e.preventDefault();
}

//next button in the mobile version carousel
function plusSlidesRightColNext2(e) {
    //carousel = photoGallery_FF photoGalleryDisplay-Thumbnail mobileVersion
    var carousel = e.currentTarget.parentNode.parentNode.parentNode.parentNode;
    //get the current carousel slide
    var num = carousel.getAttribute("data-slide");
    if (!num) num = 0;
    num++;
    //if num is the last disable TODO 1026

    showCarouselSlide2(carousel, num);

    ////display set of footer thumbnails
    //displaySetOfFooterThumbnails2(carousel, num);
}

//next button in the mobile version carousel when space or enter
function nextMiniCarouselKeypress(e) {
    if (e.key == "Enter" || e.key == " ") {
        var carousel = e.currentTarget.parentNode.parentNode.parentNode.parentNode;
        //get the current carousel slide
        var num = carousel.getAttribute("data-slide");
        if (!num) num = 0;
        num++;
        //if num is the last disable TODO 1026

        showCarouselSlide2(carousel, num);
    }
    e.preventDefault();
}

// prev button in pagination
function prevSetOfPages2(e) {
    // previtem > pagenumbers >
    var pageNumbersDiv = e.currentTarget.parentNode;
    //get alinks
    // previtem > pagenumbers > photogallerycontainer
    var photoGalleryContainer = e.currentTarget.parentNode.parentNode;
    var alinks = photoGalleryContainer.getElementsByClassName(
        "PhotoGalleryItem__CoverLink"
    );

    var $activePage = $(pageNumbersDiv).find("button.active");
    $activePage.removeClass("active");

    // if there is a next page
    if ($activePage.prev().length > 0) {
        $activePage.prev().addClass("active");
        buildPage2(alinks, $activePage.prev().val(), 12);
    } else {
        $(".photoGalleryPaginator").find("button:first").addClass("active");
    }
}

// next button in pagination
function nextSetOfPages2(e) {
    // nextitem > pagenumbers >
    var pageNumbersDiv = e.currentTarget.parentNode;
    //get alinks
    // nextitem > pagenumbers > photogallerycontainer
    var photoGalleryContainer = e.currentTarget.parentNode.parentNode;
    var alinks = photoGalleryContainer.getElementsByClassName(
        "PhotoGalleryItem__CoverLink"
    );

    var $activePage = $(pageNumbersDiv).find("button.active");

    $activePage.removeClass("active");

    if ($activePage.next().length > 0) {
        $activePage.next().addClass("active");
        buildPage2(alinks, $activePage.next().val(), 12);
    } else {
        $(".photoGalleryPaginator").find("button:last").addClass("active");
    }
}

////display the footer thumbnails in the mobile carousel
//function displayMobileFooterThumbnails2(dots) {
//    var slideIndex = 0;
//    var numberOfItems = dots.length;

//    for (i = 0; i < dots.length; i++) {
//        dots[i].className = dots[i].className.replace(" active", "");
//    }

//    for (i = 0; i < dots.length; i++) {
//        dots[i].style.display = "none";
//    }

//    if (dots.length <= 4) {
//        for (i = 0; i < dots.length; i++) {
//            dots[i].style.display = "block";
//        }
//    } else {
//        for (i = 0; i < 4; i++) {
//            dots[i].style.display = "block";
//        }
//    }
//    dots[slideIndex].className += " active";
//}

//display the footer thumbnails in the modal carousel
function displayModalFooterThumbnails2(dots, slideIndex) {
    var numberOfItems = dots.length;

    for (i = 0; i < numberOfItems; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
    }

    for (i = 0; i < numberOfItems; i++) {
        dots[i].style.display = "none";
    }

    if (numberOfItems < 8) {
        for (i = 0; i < numberOfItems; i++) {
            dots[i].style.display = "block";
        }
    } else {
        if (slideIndex < 8) {
            for (i = 0; i < 8; i++) {
                dots[i].style.display = "block";
            }
        } else {
            for (i = slideIndex - 7; i <= slideIndex; i++) {
                dots[i].style.display = "block";
            }
        }
    }
    dots[slideIndex].className += " active";
    $(dots[slideIndex]).focus();
}

////for the mobile version or rightcol version, display only 4 thumbnails
//function displaySetOfFooterThumbnails2(carousel, num) {
//    var carouselFooterThumbnails =
//        carousel.getElementsByClassName("dotsMini");

//    $(carouselFooterThumbnails).css("display", "none");

//    if (num >= carouselFooterThumbnails.length) {
//        num = carouselFooterThumbnails.length - 1;
//    }
//    if (num < 0) {
//        num = 0;
//    }

//    // start num 4 fifth image
//    var numPlusFour = num + 4; // 8
//    var totalThumbnailLessFour = carouselFooterThumbnails.length - 4; // 1

//    if (num <= totalThumbnailLessFour) {
//        $(carouselFooterThumbnails).slice(num, numPlusFour).css("display", "");
//    } else {
//        $(carouselFooterThumbnails)
//            .slice(totalThumbnailLessFour, carouselFooterThumbnails.length)
//            .css("display", "");
//    }
//}

////to do pass the photo gallery id - nflores
//function showSlides(selectedItemIndex, galleryType, compId) {
//    var i;
//    var slidesClassName = ".mySlides" + galleryType + "." + compId;
//    var dotsClassName = ".dots" + galleryType + "." + compId; //.dots-Thumbnail.x150844
//    var slides = $(slidesClassName);
//    //var dots = document.getElementsByClassName(dotsClassName);
//    var dots = $(dotsClassName);

//    //set slideindex when its the last item or first item
//    slideIndex = accomodateSelectedImageIndex(selectedItemIndex, slides.length);

//    for (i = 0; i < slides.length; i++) {
//        slides[i].style.display = "none";
//    }
//    for (i = 0; i < dots.length; i++) {
//        dots[i].className = dots[i].className.replace(" active", "");
//    }

//    //hide all the dots(55x55 thumbnails)
//    var numberOfItems = 0;

//    numberOfItems = dots.length;
//    for (i = 0; i < dots.length; i++) {
//        dots[i].style.display = "none";
//    }

//    if (slideIndex == 0 || slideIndex < 9) {
//        //display 9 dots
//        if (numberOfItems > 9) {
//            for (i = 0; i < 9; i++) {
//                dots[i].style.display = "block";
//            }
//        } else {
//            for (i = 0; i < numberOfItems; i++) {
//                dots[i].style.display = "block";
//            }
//        }
//    } else if (slideIndex >= 9) {
//        for (i = 9; i < numberOfItems; i++) {
//            dots[i].style.display = "block";
//        }
//    }

//    // document.getElementById("myDIV").style.display = "none";
//    slides[slideIndex].style.display = "block";
//    dots[slideIndex].className += " active";
//    // captionText.innerHTML = dots[slideIndex].alt;
//}

function accomodatePage(clickedPage, numberOfPages) {
    if (clickedPage <= 1) {
        return clickedPage + 1;
    }
    if (clickedPage >= numberOfPages) {
        return clickedPage - 1;
    }
    return clickedPage;
}

function accomodateSelectedImageIndex(clickedImageIndex, totalItems) {
    if (clickedImageIndex < 0) {
        return clickedImageIndex + 1;
    }
    if (clickedImageIndex >= totalItems && clickedImageIndex > 0) {
        return clickedImageIndex - 1;
    }
    return clickedImageIndex;
}

//opens the modal when user clicks enter or space for thumbnail
function openModalKeypress2(e) {
    if (e.key == "Enter" || e.key == " ") {
        openModal2(e);
    }
    e.preventDefault();
}

//opens the modal when user clicks enter or space for masonry
function openModalKeypressMasonry2(e) {
    if (e.key == "Enter" || e.key == " ") {
        openModalMasonry2(e);
    }
    e.preventDefault();
}

////display the selected image from the footer thumbnails to the minicarousel main image
//function displaySelectedMiniCarouselImage2(e) {
//    // dotsMini-Thumbnail > imageFooterThumbnails > mobileVersion
//    var carousel = e.currentTarget.parentNode.parentNode;
//    var selectedImage = e.currentTarget;
//    var slideNum = 0;
//    //get the current image index
//    if (selectedImage.hasAttribute("data-number")) {
//        slideNum = selectedImage.getAttribute("data-number");
//    }
//    showCarouselSlide2(carousel, slideNum);
//}

//display the selected image from the footer thumbnails to the minicarousel main image
function displayClickedFooterImageInTheCarousel2(e) {
    // dots > imageFooterThumbnails > mobileVersion
    var carousel = e.currentTarget.parentNode.parentNode.parentNode;
    var selectedImage = e.currentTarget;
    var slideNum = 0;
    //get the current image index
    if (selectedImage.hasAttribute("data-number")) {
        slideNum = selectedImage.getAttribute("data-number");
    }
    //to do display the main image in the modal
    showSelectedImageInModal2(carousel, slideNum);
    //showCarouselSlide2(carousel, slideNum);
}

// display selected image when enter or space
function footerImageKeypress(e) {
    if (e.key == "Enter" || e.key == " ") {
        // This assumes the HTML structure is exactly as you posted
        var carousel = e.currentTarget.parentNode.parentNode.parentNode;
        var selectedImage = e.currentTarget;
        var slideNum = 0;
        //get the current image index
        if (selectedImage.hasAttribute("data-number")) {
            slideNum = selectedImage.getAttribute("data-number");
        }
        //to do display the main image in the modal
        showSelectedImageInModal2(carousel, slideNum);
    }
    e.preventDefault();
}

//************************************************
//***** photogallery paginator functions *********
//************************************************
// build a list of page numbers for the thumbnail photo gallery
function buildPagination2(
    paginationDiv,
    clickedPage,
    numberOfPages,
    controlId
) {
    var classSelector = "#" + controlId + " " + ".photoGalleryPaginator";
    $(classSelector).empty();
    //(".photoGalleryPaginator").empty();

    for (var i = 0; i < numberOfPages; i++) {
        var myValue = i + 1;

        if (myValue == clickedPage) {
            $(classSelector).append(
                '<button class="active btn-link" value="' +
                myValue +
                '">' +
                myValue +
                "</button>"
            );
        } else {
            $(classSelector).append(
                '<button class="btn-link" value="' +
                myValue +
                '">' +
                myValue +
                "</button>"
            );
        }
    }
}

// when a page number is click in the photoGalleryPaginator
function PageClick2(paginationDiv, alinks, clickedPage) {
    //remove active from the other page
    //make the selected page active

    //$ before the variable name = jquery object
    var $activePage = $(paginationDiv).find("button.active");
    $activePage.removeClass("active");

    var btns = paginationDiv.getElementsByClassName("btn-link");
    var ctr2;

    for (ctr2 = 0; ctr2 < btns.length; ctr2++) {
        if (btns[ctr2].value == clickedPage) {
            $(btns[ctr2]).addClass("active");
        }
    }

    //display the 140x140 images set of 12 per page
    buildPage2(alinks, clickedPage, 12);
}

//display 140x140 thumbnails photo gallery
//12 thumbnails per page
function buildPage2(alinks, currPage, numberPerPage) {
    var trimStart = (currPage - 1) * numberPerPage;
    var trimEnd = trimStart + numberPerPage;

    // hide  show the main 140x140 thumbnails
    for (ctr2 = 0; ctr2 < alinks.length; ctr2++) {
        alinks[ctr2].style.display = "none";
        if (ctr2 >= trimStart && ctr2 < trimEnd) {
            alinks[ctr2].style.display = "block";
        }
    }
}

//switch to mobile view for the photogallery on tablet and mobile
function updatePhotoGalleryView() {
    var $containerWidth = $(window).width();

    if ($containerWidth <= 767) {
        //display mobile version
        removeHandler();
    }
}

function removeHandler() {
    var ctr2;

    //search for all the mobile version of the carousel applies to all options
    var photoCarouselMobile = document.querySelectorAll(
        ".photoGallery_FF.photoGalleryDisplay-Thumbnail.mobileVersion, .photoGallery_FF.photoGalleryDisplay-Masonry.mobileVersion, .photoGallery_FF.photoGalleryDisplay-HeroCarousel.mobileVersion"
    );

    photoCarouselMobile.forEach(function (element, index) {
        //loop through every a tags w img inside the carousel and remove the eventlistener
        //modal is disable on mobile
        var carouselImg = element.getElementsByClassName(
            "PhotoGalleryItem__CoverLink"
        );

        for (ctr2 = 0; ctr2 < carouselImg.length; ctr2++) {
            // Assign an event listener to the image
            carouselImg[ctr2].removeEventListener("click", openCarouselModal2);
        }
    });
}

//hide open photo gallery modal when resizing the screen
function closeOpenPhotoGalleryModal() {
    $(".photoGallery_FF.modalPhotoGallery").hide();
}


function setLocationAnchorsForTranslation(compId) {
    //get the hashtag in the url
    var urlHash = location.hash;
    var defhashValue = "En_" + compId;

    //check if url has hashtag
    if (urlHash !== "") {
        //url has hashtag, remove non alphanumeric characters
        var hashValue = urlHash.replace('#', '');
        var xid = hashValue.substr(hashValue.indexOf('_') + 1, hashValue.length - hashValue.indexOf('_'));
        //if compid is not the same hash, do not replace the default hashValue
        if (compId != xid) {
            hashValue = defhashValue;
        }

        $('button[data-id=' + hashValue + ']').click();
        $("#mobileDDLTranslate_" + compId).val(hashValue).change();
    }
    else {
        //activate the button with the data-id
        $('button[data-id=' + defhashValue + ']').click();
        $("#mobileDDLTranslate_" + compId).val(defhashValue).change();

    }
}

function setLocationAnchorsForFullPageTranslation() {

    //get the hashtag in the url
    var urlHash = location.hash;
    var defhashValue = "En";
    var hashValue = "";

    //check if url has hashtag
    if (urlHash !== "") {
        //url has hashtag, remove non alphanumeric characters
        hashValue = urlHash.replace('#', '');
    }
    else {
        hashValue = defhashValue;
    }

    //set the dropdown selected option
    $('#languagelist option[value*="' + hashValue + '"]').attr('selected', true);

    $(".commonClass").hide();
    $("." + hashValue).show();
    $(" .btnTranslation").removeClass("myactivebutton");
    $('a[data-id=' + hashValue + ']').addClass("myactivebutton");
}